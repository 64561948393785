export const locale = {
  lang: 'fr',
  data: {
    LOCALE: {
      fr: "Français",
      en: "Anglais",
      de: "Allemand"
    },
    NAV: {
      EXPLORING: {
        LABEL: "Explorer",
      },
      CLUSTERS_AND_PARCELS: {
        LABEL: "Clusters & Parcelles",
      },
      ROVERS: {
        LABEL: "Rovers",
      },
      MODELS: {
        LABEL: "Modèles",
      },
      USERS: {
        LABEL: "Utilisateurs & rôles",
      },
      ORGANIZATIONS: {
        LABEL: "Organisations",
      },
      AGRONOMY: {
        LABEL: "Agronomie",
      },
      ACTIVITY: {
        LABEL: "Activité",
      },
      LOGS: {
        LABEL: "Logs",
      },
      KPI: {
        LABEL: "KPI",
      },
      HELP: {
        LABEL: "Aide",
      },
      MAINTENANCE: {
        LABEL: "Maintenance",
      }
    },
    EXPLORE: {
      FORM: {
        USER: {
          PLACEHOLDER: "Rechercher par client"
        }
      },
      FILTERS: {
        ROVER: "Rover",
        TAXIWAY: "Chemin de taxi",
        PARCEL: "Parcelle",
        HDZ: "ZDH",
        CHARGING_STATION: "Station de recharge",
        WAITING_ZONE: "Zone d'attente",
        GPS_RECORDING: "Enregistrement GPS"
      },
      CLUSTER: {
        LABEL: "Cluster"
      },
      PARCEL: {
        LABEL: "Parcelle",
        INFO_LABEL: "Infos"
      },
      ROVER: {
        OVERLAY: {
          INFORMATION_SECTION: {
            TITLE: "Informations",
          },
          ACTION_SECTION: {
            TITLE: "Actions"
          },
          DISPLAY_SECTION: {
            TITLE: "Afficher",
            ODOMETRY: "Historique de déplacement"
          }
        },
        BATTERY: {
          LABEL: "Batterie"
        },
        OPERATING_STATUS: {
          LABEL: "Etat"
        }
      }
    },
    AGRONOMY: {
      CROP_TYPES: {
        LIST: {
          ADD_CROP_TYPE_BUTTON: "Ajouter un type de culture",
          HEADER: {
            NAME: "Nom",
            CAP_CODE: "Code PAC",
            CHAPTER_NAME: "Famille de culture",
            SEEDING_DENSITY: "Densité de semis",
            INTER_ROW_SPACING: "Espacement inter-rang",
            COUNTRY: "Pays",
            ACTIONS: "Actions",
          },
        },
        ADD_CROP_TYPE: {
          TITLE: "Ajouter un type de culture",
          FORM: {
            NAME: "Nom",
            CAP_CODE: "Code PAC",
            CHAPTER_NAME: "Famille de culture",
            CAP_CROP_TYPE: "Nom de la culture PAC",
            SEEDING_DENSITY_UNIT: {
              LABEL: "Unité de densité de semis",
              SEEDS_PER_HA: "graines/ha",
              SEEDS_PER_SQ_METER: "graines/m²"
            },
            SEEDING_DENSITY_MIN: "Densité de semis minimale",
            SEEDING_DENSITY_MAX: "Densité de semis maximale",
            SEEDING_DENSITY: {
              ERRORS: {
                NEGATIVE_VALUES: "La densité de semis dois êtres supérieur à 0",
                INVALID_RANGE: "L'interval de densité de semis est invalide"
              }
            },
            INTER_ROW_SPACING_ARRAY: "Espacement inter-rangs",
            SPACING_VALUE: "Valeur",
            SPACING_UNIT: "Unité (par ex. cm)",
            INEQUALITY_SYMBOL: "Symbole d'inégalité (< ou >)",
            ADD_SPACING: "Ajouter un espacement",
            SUBMIT: "Soumettre",
          },
        },
      },
    },
    CLUSTERS: {
      LIST: {
        INFO_LABEL: "INFO",
        LABEL: "Cluster"
      },
      MANAGEMENT: {
        TITLE: 'Cluster',
        CONFIRM_DELETE: "Êtes-vous sûr de vouloir supprimer ce cluster ?",
        PREVIEW_SELECTION_ERROR: 'Impossible de selectioner cet élément car il est en cours de prévisualisation !',
        ACTIONS: {
          TITLE: 'Actions',
          ADD_PARCEL: 'Ajouter une parcelle',
          ADD_HDZ: 'Ajouter une zone de direction homogène',
          ADD_TAXIWAY: 'Ajouter un chemin de taxi',
          ADD_CHARGING_STATION: 'Ajouter une station de recharge',
          ADD_MANUAL_TAXIWAY: 'Ajouter un chemin de taxi manuel',
          ADD_GPS_RECORDING: 'Ajouter un enregistrement GPS',
          ADD_WAITING_ZONE: 'Ajouter une zone d\'attente',
          ADD_ROVER: 'Attribuer un rover',
          ADD_USER: 'Ajouter un utilisateur',
          ADD_ACCIDENT: 'Ajouter un accident',
          ADD_ACCIDENT_TYPE: 'Ajouter un type d\'accident',
          ASSIGN_CROP: 'Assigner une culture',
          END_CROP: 'Finaliser la culture',
          UPDATE_CROP_STATUS: 'Mettre à jour le statut de la culture',
          UPDATE_CROP_BLIND_WEEDING_PATH: 'Modifier le chemin de désherbage à l\'aveugle',
          CLUSTER_CONFIGURATION: 'Editer la configuration rover',
          DOWNLOAD_GEOFENCE: 'Télécharger la geofence',
          DOWNLOAD_TAXIWAY: 'Télécharger la taxiway',
          UPDATE_GEOFENCE: 'Modifier la geofence',
        },
        DETAILS: {
          TITLE: "Informations",
          TABS: {
            ROVERS: "Rovers",
            CONFIRM_UNASSIGNMENT: "Êtes-vous sûr de vouloir désassigner ce rover du cluster ?",
            USERS: "Utilisateurs"
          },
        },
      },
      CONFIGURATION: {
        TITLE: "Editer la configuration rover"
      },
      EDIT_NAME: {
        TITLE: "Renommer le cluster",
        FORM: {
          NAME: "Nom",
          SUBMIT: "Valider"
        }
      },
      ADD_USER: {
        TITLE: "Ajouter un utilisateur",
        FORM: {
          USERS: "Utilisateurs",
          USERS_PLACEHOLDER: "Recherchez ici",
          SUBMIT: "Ajouter"
        }
      },
      ASSIGN_CROP: {
        TITLE: "Assigner une culture",
        FORM: {
          CROP_TYPE: "Culture",
          PARCEL: "Parcelle",
          INTER_ROW_SPACING: "Espacement inter-rang",
          SEEDING_DENSITY: "Densité de semis",
          SEEDING_DENSITY_HINT: "Densité comprises entre {{min}} et {{max}} {{unit}}",
          SEEDING_DEPTH: "Profondeur de semis",
          SEEDING_DATE: "Date de semis",
          SUBMIT: "Assigner"
        }
      },
      ACCIDENT: {
        ADD: {
          TITLE: "Ajouter un accident à une parcelle",
        },
        EDIT: {
          TITLE: "Editer un accident",
        },
        FORM: {
          START_DATE: "Date de l'accident",
          START_DATE_PLACEHOLDER: "Saisissez une date",
          END_DATE: "Date de fin",
          END_DATE_PLACEHOLDER: "Saisissez une date (Optionel)",
          PERCENTAGE: "Pourcentage affecté",
          PERCENTAGE_PLACEHOLDER: "Saisissez un pourcentage",
          ACCIDENT_TYPE: "Type d'accident",
          ERRORS: {
            START_DATE: "La date de l'accident ne peut pas être dans le futur",
            START_DATE_MISSING: "La date de l'accident ne peut pas être nulle",
            END_DATE: "La date de fin ne peut pas être avant la date de début",
            PERCENTAGE: "Le pourcentage doit être compris entre 0 et 100",
          },
          MANAGE_ACCIDENT_TYPE: "Gestion des types d'accidents...",
        }
      },
      ACCIDENT_TYPE: {
        EDIT: {
          TITLE: "Modifier un type d'accident",
        },
        ADD: {
          TITLE: "Ajouter un type d'accident",
        },
        MANAGE: {
          TITLE: "Gestion des types d'accidents",
        },
        GROUP: {
          PESTS: "Ravageurs",
          AERIAL_DISEASES: "Maladies aériennes",
          PHYSICOCHEMICAL: "Physico-chimique",
          CLIMATE_RELATED: "Liés au climat",
          SPRAY_RELATED: "Liés à la pulverisation",
        },
        TYPE: {
          SLUGS: "Limaces",
          OSCINIAS: "Oscinies",
          WIREWORMS: "Taupins",
          GEOMYZAS: 'Géomyzes',
          SEEDLING_MAGGOTS: 'Mouches des semis',
          CUTWORMS: 'Vers gris',
          RUST: 'Rouille',
          PSEUDO_PHOSPHORUS_DEFICIENCY: 'Pseudo carence en phosphore',
          ZINC_DEFICIENCY: 'Carence en zinc',
          AMONIA_POISONING: 'Intoxication ammoniacale',
          NITROGEN_DEFICIENCY: 'Carence en azote',
          WATER_STRESS: 'Stress hydrique',
          PHYTOTOXICITY_AUXIN_DERIVATIVES: 'Phytotoxicité dérivés auxinique',
          NITROGEN_BURN: 'Brûlure d’azote',
        },
        FORM: {
          NAME: "Nom",
          GROUP: "Groupe",
          ADD_SUBMIT: "Ajouter",
          EDIT_SUBMIT: "Modifier"
        }
      },
      END_CROP: {
        TITLE: "Finaliser la culture",
        FORM: {
          END_DATE: "Date de fin",
          END_DATE_PLACEHOLDER: "Saisissez une date",
          SUBMIT: "Finaliser"
        }
      },
      CROP_STATUS: {
        TITLE: "Mettre à jour le statut de la culture",
        FORM: {
          LOWEST_STAGE: "Stade d'évolution le plus bas",
          MEDIAN_STAGE: "Stade d'évolution médian",
          HIGHEST_STAGE: "Stade d'évolution le plus haut",
          DATE: "Date",
          TIME: "Heure",
          LOWEST_STAGE_PLACEHOLDER: "Ex: 9",
          MEDIAN_STAGE_PLACEHOLDER: "Ex: 12",
          HIGHEST_STAGE_PLACEHOLDER: "Ex: 18",
          ERRORS: {
            STAGE_MIN: "Le stade ne peux pas être inférieur à 9",
            STAGE_MAX: "Le stade ne peux pas être supérieur à 19",
            MEDIAN_STAGE_INVALID: "Le stade doit être compris entre 9 et 19 ou égale à -1 si trop hétérogène",
          },
          SUBMIT: "Mettre à jour"
        },
      },
      PARCELS: {
        EDIT_NAME: {
          TITLE: "Renommer la parcelle",
          FORM: {
            NAME: "Nom",
            SUBMIT: "Valider"
          }
        },
      },
      PARCEL_DETAILS: {
        TITLE: "Parcelle",
        DELETE_BUTTON: "Supprimer",
        AREA_SUFFIX: "de surface",
        CONFIRM_DELETE: "Êtes-vous sûr de vouloir supprimer cette parcelle ?",
        CONFIRM_DELETE_GPS: "Êtes-vous sûr de vouloir supprimer cet enregistrement GPS ?",
        CONFIRM_DELETE_CROP_STATUS: "Êtes-vous sûr de vouloir supprimer ce statut de culture ?",
        CONFIRM_COMPLETE_ACCIDENT: "Êtes-vous sûr de vouloir clotûrer cet accident sur cette parcelle ?",
        TABS: {
          INFORMATION: "Informations",
          GPS_TRACKS: "Enregistrements GPS",
          CROP: "Cultures",
          ACCIDENT: "Accidents"
        },
        CROP: {
          TYPE: "Type de culture",
          SEEDING_DATE: "Date de semis",
          SEEDING_DENSITY: "Densité de semis",
          INTER_ROW_SPACING: "Espacement inter-rang",
          SEEDING_DEPTH: "Profondeur de semis",
          STATUS: "Statut",
          GENERATE_BLIND_WEEDING_PATH: {
            TITLE: "Générer un chemin de désherbage à l'aveugle",
            FORM: {
              ANGLE: "Angle",
              SUBMIT: "Valider"
            }
          }
        },
        CARTOGRAPHY: {
          AVAILABLE: "Cartographie disponible",
          UNAVAILABLE: "Cartographie non disponible",
        },
        ROAD_MAP: {
          AVAILABLE: "Itinéraire disponible",
          UNAVAILABLE: "Itinéraire non disponible",
          USED_BY_ROVERS: "Disponible sur le(s) rover(s)",
        },
      },
      CHARGING_STATION_DETAILS: {
        TITLE: "Station de recharge",
        DELETE_BUTTON: "Supprimer",
        RELEASE_BUTTON: "Libérer",
        CONFIRM_DELETE: "Êtes-vous sûr de vouloir supprimer cette station de recharge ?"
      },
      WAITING_ZONE_DETAILS: {
        TITLE: "Zone d'attente",
        DELETE_BUTTON: "Supprimer",
        CONFIRM_DELETE: "Êtes-vous sûr de vouloir supprimer cette zone d'attente ?"
      },
      TAXIWAY_DETAILS: {
        TITLE: "Chemin de taxi",
        DELETE_BUTTON: "Supprimer",
        CONFIRM_DELETE: "Êtes-vous sûr de vouloir supprimer ce chemin de taxi ?"
      },
      MANUAL_TAXIWAY_DETAILS: {
        TITLE: "Chemin de taxi manuel",
        DELETE_BUTTON: "Supprimer",
        CONFIRM_DELETE: "Êtes-vous sûr de vouloir supprimer ce chemin de taxi manuel ?"
      },
      ADD_CLUSTER: {
        BUTTON_TEXT: "Créer un cluster",
        TITLE: "Créer un cluster",
        SUBMIT: "Créer",
        FORM: {
          NAME: "Nom",
          NAME_PLACEHOLDER: "Haricot",
          USERS: "Utilisateurs",
          USERS_PLACEHOLDER: "Ajouter des utilisateurs"
        }
      },
      ADD_PARCEL: {
        BUTTON_TEXT: "Créer une parcelle",
        TITLE: "Créer une parcelle",
        SUBMIT: "Créer",
        FORM: {
          NAME: "Nom",
          NAME_PLACEHOLDER: "Tulipe",
          CLUSTER: "Cluster",
          GEOFENCE_FILE: "Geofence",
          GEOFENCE_FILE_PLACEHOLDER: "fichier GPX",
          TAXIWAY_FILE: "Chemin de taxi",
          TAXIWAY_FILE_PLACEHOLDER: "fichier GPX",
          HDZ_FILE: "ZDHs",
          HDZ_FILE_PLACEHOLDER: "fichier GPX",
        }
      },
      ADD_ROVER: {
        TITLE: "Attribuer un rover au cluster",
        NO_EXISTING_ROVER: "Vous devez d'abord créer un rover !",
        SUBMIT: "Attribuer",
        FORM: {
          ROVER: "Rover",
        }
      },
      ADD_HDZ: {
        BUTTON_TEXT: "Créer une zone de direction homogène",
        TITLE: "Créer une zone de direction homogène",
        NO_EXISTING_PARCEL: "Vous devez d'abord créer une parcelle !",
        SUBMIT: "Créer",
        FORM: {
          PARCEL: "Parcelle",
          HDZ_FILE: "ZDHs",
          HDZ_FILE_PLACEHOLDER: "fichier GPX",
        }
      },
      ADD_TAXIWAY: {
        BUTTON_TEXT: "Créer un chemin de taxi",
        TITLE: "Créer un chemin de taxi",
        SUBMIT: "Créer",
        FORM: {
          NAME: "Nom",
          NAME_PLACEHOLDER: "Tulipe",
          CLUSTER: "Cluster",
          TAXIWAY_FILE: "Chemin de taxi",
          TAXIWAY_FILE_PLACEHOLDER: "fichier GPX",
        }
      },
      ADD_GPS_RECORDING: {
        BUTTON_TEXT: "Ajouter un enregistrement GPS",
        TITLE: "Ajouter un enregistrement GPS",
        SUBMIT: "Créer",
        FORM: {
          NAME: "Nom",
          PARCEL: "Parcelle",
          NAME_PLACEHOLDER: "Path 1",
          CLUSTER: "Cluster",
          PATH_FILE: "Enregistrement",
          PATH_FILE_PLACEHOLDER: "fichier GPX",
        }
      },
      ADD_CHARGING_STATION: {
        BUTTON_TEXT: "Créer une station de recharge",
        TITLE: "Créer une station de recharge",
        SUBMIT: "Créer",
        FORM: {
          NAME: "Nom",
          NAME_PLACEHOLDER: "Tulipe",
          LONGITUDE: "Longitude",
          LATITUDE: "Latitude",
          ALTITUDE: "Altitude",
          YAW: "Direction (degrès)",
          SSID: "SSID (Nom du réseau wifi)",
          PASSWORD: "Mot de passe (du réseau wifi)",
        }
      },
      EDIT_CHARGING_STATION: {
        TITLE: "Modifier une station de recharge"
      },
      ADD_MANUAL_TAXIWAY: {
        BUTTON_TEXT: "Créer un chemin de taxi manuel",
        INSTRUCTIONS: "Vous pouvez tracer le chemin de taxi manuel en cliquant sur les deux points d'encrages que vous souhaitez relier.",
        TITLE: "Créer un chemin de taxi manuel",
        SUBMIT: "Créer",
        FORM: {
          NAME: "Nom (optionnel)",
          NAME_PLACEHOLDER: "Tulipe",
        }
      },
      ADD_WAITING_ZONE: {
        BUTTON_TEXT: "Créer une zone d'attente",
        TITLE: "Créer une zone d'attente",
        SUBMIT: "Créer",
        FORM: {
          NAME: "Nom",
          NAME_PLACEHOLDER: "Tulipe",
          CLUSTER: "Cluster",
          GEOFENCE_FILE: "Geofence",
          GEOFENCE_FILE_PLACEHOLDER: "fichier GPX",
        }
      },
      UPDATE_PARCEL_GEOFENCE: {
        BUTTON_TEXT: "Modifier la parcelle",
        TITLE: "Modifier la géofence d'une parcelle",
        SUBMIT: "Modifier",
        FORM: {
          NAME: "Nom",
          GEOFENCE_FILE: "Geofence",
          GEOFENCE_FILE_PLACEHOLDER: "fichier GPX ou CSV",
        }
      },
    },
    ROVERS: {
      CONFIGURATION: {
        TITLE: "Configuration",
        ROUTER: {
          TITLE: "Routeur",
          FORM: {
            USERNAME: "Nom d'utilisateur",
            PASSWORD: "Mot de passe"
          }
        },
        THINGSBOARD: {
          TITLE: "Thingsboard",
          FORM: {
            DASHBOARD_LINK: "Lien du dashboard",
          }
        },
        ROVER: {
          TITLE: "Rover",
          FORM: {
            KEY_LABEL: "Clé",
            VALUE_LABEL: "Valeur",
            APPLIED_VALUE_LABEL: "Valeur appliquée",
          }
        }
      },
      CREATE_ROVER: {
        NAV_BUTTON_TEXT: "Créer un rover",
        TITLE: "Créer un rover",
        FORM: {
          SERIAL_NUMBER: "Numéro de série (optionnel)",
          SERIAL_NUMBER_PLACEHOLDER: "ex: GS0001",
          SERIAL_NUMBER_ERROR: "Le numéro de série doit être composé de 4 chiffres",
          MODEL: "Modèle",
          BATTERY_CAPACITY: "Capacité de la batterie (en kWh)",
          INTERNAL_IDENTIFIER: "Identifiant interne",
          OWNER_ORGANIZATION: "Organisation propriétaire",
          USERS: "Utilisateurs",
          SUBMIT: "Créer"
        }
      },
      EDIT_NAME: {
        TITLE: "Renommer le rover",
        FORM: {
          NAME: "Nom",
          SUBMIT: "Valider"
        }
      },
      MODELS: {
        TITLE: "Gestion des modèles",
        ADD: "Ajouter un modèle",
        ADD_MODEL: {
          NAV_BUTTON_TEXT: "Créer un modèle",
          TITLE: "Créer un modèle",
          FORM: {
            NAME: "Nom",
            NAME_PLACEHOLDER: "ex: Prototype V1",
            SERIAL_PREFIX: "Serial prefix",
            SERIAL_PREFIX_PLACEHOLDER: "ex: GS",
            LENGTH: "Longueur (en cm)",
            LENGTH_PLACEHOLDER: "ex: 3000",
            WIDTH: "Largeur (en cm)",
            WIDTH_PLACEHOLDER: "ex: 2000",
            HEIGHT: "Hauteur (en cm)",
            HEIGHT_PLACEHOLDER: "ex: 1000",
            EMPTY_WEIGHT: "Poids (en kg)",
            EMPTY_WEIGHT_PLACEHOLDER: "ex: 1000",
            ENERGY_TYPE: "Type dénergie",
            BATTERY_CAPACITY_MAX: "Capacité de batterie max (en kWh)",
            BATTERY_CAPACITY_MIN: "Capacité de batterie min (en kWh)",
            BATTERY_CAPACITY_PLACEHOLDER: "ex: 20",
            FUEL_CAPACITY: "Capacité du réservoir (en l)",
            FUEL_CAPACITY_PLACEHOLDER: "ex: 100",
            WORKING_WIDTH_MIN: "Largeur de travail min (en cm)",
            WORKING_WIDTH_MAX: "Largeur de travail max (en cm)",
            WORKING_WIDTH_PLACEHOLDER: "ex: 1000",
            MAX_TOOLS_MODULE_NB: "Nombre de modules outils max",
            MAX_TOOLS_MODULE_NB_PLACEHOLDER: "ex: 3",
            AUTONOMOUS_MAX_HOURS: "Nombre d'heures d'autonomie max",
            AUTONOMOUS_MAX_HOURS_PLACEHOLDER: "ex: 11",
            MAX_FIELD_CAPACITY: "Débit de chantier max (en ha/j)",
            MAX_FIELD_CAPACITY_PLACEHOLDER: "ex: 30",
            MODEL_COMPONENT_TYPE: "Catégorie de componsant",
            NB_OF_REQUIRED_COMPONENTS_PLACEHOLDER: "1",
            SUBMIT: "Créer"
          }
        },
        EDIT_MODEL: {
          NAV_BUTTON_TEXT: "Modifier un modèle",
          TITLE: "Modifier un modèle",
          FORM: {
            NAME: "Nom",
            NAME_PLACEHOLDER: "ex: Prototype V1",
            SERIAL_PREFIX: "Serial prefix",
            SERIAL_PREFIX_PLACEHOLDER: "ex: GS",
            LENGTH: "Longueur (en cm)",
            LENGTH_PLACEHOLDER: "ex: 3000",
            WIDTH: "Largeur (en cm)",
            WIDTH_PLACEHOLDER: "ex: 2000",
            HEIGHT: "Hauteur (en cm)",
            HEIGHT_PLACEHOLDER: "ex: 1000",
            EMPTY_WEIGHT: "Poids (en kg)",
            EMPTY_WEIGHT_PLACEHOLDER: "ex: 1000",
            ENERGY_TYPE: "Type dénergie",
            BATTERY_CAPACITY_MAX: "Capacité de batterie max (en kWh)",
            BATTERY_CAPACITY_MIN: "Capacité de batterie min (en kWh)",
            BATTERY_CAPACITY_PLACEHOLDER: "ex: 20",
            FUEL_CAPACITY: "Capacité du réservoir (en l)",
            FUEL_CAPACITY_PLACEHOLDER: "ex: 100",
            WORKING_WIDTH_MIN: "Largeur de travail min (en cm)",
            WORKING_WIDTH_MAX: "Largeur de travail max (en cm)",
            WORKING_WIDTH_PLACEHOLDER: "ex: 1000",
            MAX_TOOLS_MODULE_NB: "Nombre de modules outils max",
            MAX_TOOLS_MODULE_NB_PLACEHOLDER: "ex: 3",
            AUTONOMOUS_MAX_HOURS: "Nombre d'heures d'autonomie max",
            AUTONOMOUS_MAX_HOURS_PLACEHOLDER: "ex: 11",
            MAX_FIELD_CAPACITY: "Débit de chantier max (en ha/j)",
            MAX_FIELD_CAPACITY_PLACEHOLDER: "ex: 30",
            MODEL_COMPONENT_TYPE: "Catégorie de componsant",
            NB_OF_REQUIRED_COMPONENTS_PLACEHOLDER: "1",
            SUBMIT: "Modifier"
          }
        },
        INFORMATIONS: {
          TITLE: "Détails du modèle",
          NAME: "Nom",
          SERIAL_PREFIX: "Serial prefix",
          LENGTH: "Longueur (en cm)",
          WIDTH: "Largeur (en cm)",
          HEIGHT: "Hauteur (en cm)",
          EMPTY_WEIGHT: "Poids (en kg)",
          ENERGY_TYPE: "Type d'énergie",
          BATTERY_CAPACITY: "Capacité de la batterie (en kWh)",
          FUEL_CAPACITY: "Capacité du réservoir (en l)",
          WORKING_WIDTH: "Largeur de travail (en cm)",
          MAX_TOOLS_MODULE_NB: "Nombre de modules outils max",
          AUTONOMOUS_MAX_HOURS: "Nombre d'heures d'autonomie max",
          MAX_FIELD_CAPACITY: "Débit de chantier max (en ha/j)",
        },
        ROVER_CONFIGURATION: {
          TITLE: "Configuration des rovers"
        },
        COMPONENT_TYPES: {
          TITLE: "Catégories de composants",
          NAME_HEADER: "Nom",
          NB_OF_REQUIRED_COMPONENTS_HEADER: "Quantité",
          ADD: "Ajouter un type",
        },
        LINKED_ROVERS: {
          TITLE: "Rovers associés",
          NAME_HEADER: "Nom",
          NB_OF_COMPONENTS_HEADER: "Composants installés"
        },
        ENERGY_TYPE: {
          ELECTRIC: "Electrique",
          HYBRID: "Hybride",
          THERMAL: "Thermique",
        }
      },
      COMPONENTS: {
        TYPES: {
          ADD_TYPE: {
            NAV_BUTTON_TEXT: "Créer un type de composant",
            TITLE: "Créer un type de composant",
            FORM: {
              NAME: "Nom",
              NAME_PLACEHOLDER: "Ex: Groupe de direction",
              SUBMIT: "Créer",
            }
          }
        },
        ADD_COMPONENT: {
          NAV_BUTTON_TEXT: "Ajouter un componsant",
          TITLE: "Ajouter un componsant",
          FORM: {
            NAME: "Nom",
            NAME_PLACEHOLDER: "Ex: Caméra 1",
            TYPE: "Type de componsant",
            LOCAL_EMBEDDED_ID: "Identifiant système local",
            LOCAL_EMBEDDED_ID_PLACEHOLDER: "Ex: cam_nav_1",
            INSTALLATION_DATE: "Date d'installation (Optionnel)",
            INSTALLATION_DATE_PLACEHOLDER: "01/10/2023",
            ROVER: "Rover",
            SUBMIT: "Ajouter",
          }
        }
      },
      OPERATING_STATUS: {
        STATUS: {
          CHARGING: "En charge",
          IDLE: "Inactif",
          MANUAL_DRIVE: "Manuel",
          WEEDING: "Désherbage",
          EMERGENCY_STOP_ENGAGED: "En arrêt d'urgence",
          DEFECT: "En défaut",
          //New status (ROBI-2152)
          MANUAL: "En mode manuel",
          AUTONOMOUS: "En mode Autonome",
          EMERGENCY_STOP: "En arrêt d'urgence"
        }
      }
    },
    ROVER_DETAILS: {
      TITLE: "Rover",
      ERRORS: {
        NOT_ASSIGNED_TO_CLUSTER: "Le rover doit être assigné à un cluster pour effectuer cette action"
      },
      STATUS: {
        ASSIGNED_TO_CLUSTER: "Assigné au cluster ",
        NOT_ASSIGNED_TO_CLUSTER: "Non assigné à un cluster",
        ASSIGNED_TO_ORGANIZATION: "Assigné à l'organisation ",
        NOT_ASSIGNED_TO_ORGANIZATION: "Non assigné à une organisation",
        LAST_SYNC: "Dernière synchronization",
        BATTERY_PERCENTAGE: "de batterie",
        DASH: {
          ACTIVE_TOOLS_DISTANCE: "parcourus par les outils",
          ACTIVE_TOOLS_DURATION: "de fonctionnement des outils",
          MOVING_DURATION: "de déplacement",
          OPERATING_TIME_DURATION: "de travail",
          ODOMETER: "parcourus",
        },
        STATS_NAV_BUTTON: "Etat du rover",
        MAINTENANCE_NAV_BUTTON: "Programme de maintenance",
        COMPONENTS_NAV_BUTTON: "Composants",
        ACTIONS: {
          CONFIRM_UNASSIGNMENT: "Êtes-vous sûr de vouloir désassigner ce rover de cette organisation?"
        }
      },
      EVENT_HISTORY: {
        TITLE: "Historique",
        SEE_MORE: "Voir plus",
        ERROR: {
          TITLE: "Erreur",
          CODE: "Code",
          DESCRIPTION: "Déscription",
          POSITION: "Position",
          MESSAGE: "Message",
        }
      },
      MISSION_HISTORY: {
        TITLE: "Missions commandées",
        MISSION: {
          TITLE: "Mission",
          STATUS: "Status",
          ORCHESTRATION_STATUS: "Status d'orchestration",
          TYPE: "Type",
          PARCEL: "Parcelle ciblée",
          CREATION_DATE: "Commandé le",
          ABORT: "Finaliser",
          FORCE_ABORT: "Annuler",
        }
      },
      ACTIONS: {
        TITLE: "Actions",
        SCHEDULE_TASK_BUTTON: "Planifier une tâche",
        STANDBY_BUTTON: "Mettre en veille",
        WAKEUP_BUTTON: "Sortir de veille",
        SCHEDULE_TASK_DIALOG: {
          TITLE: "Planifier une tâche",
          FORM: {
            ROVER: "Rover",
            PARCEL: {
              LABEL: "Parcelle",
              ERRORS: {
                REQUIRED: "La parcelle est requise",
                MISSING_GPS_RECORDINGS: "Aucun enregistrement GPS disponible pour cette parcelle"
              }
            },
            TYPE: {
              LABEL: "Type",
            },
            GPS_RECORDING: "Enregistrement GPS",
            HDZ: "ZDH (multiple)",
            SUBMIT: "Planifier"
          }
        }
      },
      TOOL_STATUS: {
        TITLE: "Status"
      },
      COMPONENTS: {
        TITLE: "Composants",
        EDIT: "Editer",
        NAME_HEADER: "Nom",
        LOCAL_EMBEDDED_ID_HEADER: "Identifiant local",
        INSTALLATION_DATE_HEADER: "Date d'installation",
        ADD_COMPONENT: "Ajouter un composant",
        EMPTY_TEXT: "Le modèle ne possède aucun composant"
      }
    },
    MISSIONS: {
      DETAILS: {
        TITLE: "Mission",
        CHART: {
          DURATION: {
            TITLE: "Durée",
            TOTAL_DURATION: "Durée totale de la mission",
          },
          DISTANCE: {
            TITLE: "Distance",
            TOTAL_DISTANCE: "Distance totale de la mission",
          },
          NO_DATA: "Pas de données disponibles",
        },
        UNCHECKED_ERRORS: {
          TITLE: "Erreurs critiques"
        },
        CHECKED_ERRORS: {
          TITLE: "Erreurs non critiques"
        },
      }
    },
    ROVER_STATS: {
      TITLE: "Rover",
      TEMPERATURE: {
        LABEL: "Température",
      },
      POWER_CON: {
        LABEL: "Consommation"
      },
      CHARTS: {
        NO_AVAILABLE_DATA: "Aucune donnée disponible",
        RESTART_STREAMING: "Reprendre le streaming",
        PAUSE_STREAMING: "Mettre en pause le streaming",
        TIMELINE_FILTERS: {
          1: "1 minute",
          5: "5 minutes",
          10: "10 minutes",
          15: "15 minutes",
          20: "20 minutes",
          25: "25 minutes",
          30: "30 minutes",
          60: "1 heure",
          120: "2 heures",
          720: "12 heures",
          1440: "24 heures",
        },
        POWER_CON: {
          X_AXIS_LABEL: "Temps",
          Y_AXIS_LABEL: "Consommation (W)",
          LEGEND_TITLE: 'Légende'
        },
        TEMPERATURE: {
          X_AXIS_LABEL: "Temps",
          Y_AXIS_LABEL: "Température (C°)",
          LEGEND_TITLE: 'Légende'
        }
      }
    },
    USERS: {
      DISCONNECT: "Déconnexion",
      ROLES: {
        ADMIN: "Administrateur",
        OPERATOR: "Opérateur",
        USER: "Utilisateur",
      },
      CONFIRM_DELETE: "Êtes-vous sûr de vouloir supprimer cet utilisateur ?",
      LIST: {
        CREATE_USER_BUTTON: "Créer un utilisateur",
        HEADER: {
          NAME: "Nom",
          EMAIL: "Email",
          ROLE: "Rôle",
          ACTIONS: "Actions"
        },
        ACTIONS: {
          SEE_DETAILS: "Voir",
          DELETE_USER: "Supprimer"
        }
      },
      UPDATE_USER: {
        FORM: {
          SUBMIT: "Mettre à jour",
        }
      },
      ADD_USER: {
        TITLE: "Créer un utilisateur",
        FORM: {
          FIRSTNAME: "Prénom",
          LASTNAME: "Nom",
          EMAIL: "Email",
          PASSWORD: "Mot de passe",
          PHONE_NUMBER: "Numéro de téléphone",
          DOB: "Date de naissance",
          DOB_PLACEHOLDER: "jj/mm/aaaa",
          ROLE: "Rôle",
          ORGANIZATION: "Organisation",
          SUBMIT: "Créer"
        }
      },
    },
    ORGANIZATIONS: {
      AFFILIATIONS: {
        DETAILS: {
          TITLE: "Détails de l'affiliation",
          CREATED_BY: "Demandeur",
          STATUS: "Statut",
        },
        LIST: {
          HEADER: {
            COMPANY_NAME: "Raison sociale",
            APPROVED_DATE: "Date d'approbation",
            REQUEST_DATE: "Date de demande",
            CREATED_BY: "Demandé par",
            STATUS: "Statut",
            ACTIONS: "Actions"
          },
          ACTIONS: {
            APPROVE: "Approuver",
            REJECT: "Rejeter",
            DELETE: "Supprimer",
            INFOS: "Informations",
            CONFIRM_SUPPRESSION: "Voulez-vous vraiment supprimer cette affiliation ?",
            CHANGE_STATUS: {
              APPROVED: "Voulez-vous vraiment approuver cette demande ?",
              REJECTED: "Voulez-vous vraiment rejeter cette demande ?",
            },
          },
          STATUS: {
            PENDING: "En attente de validation",
            REJECTED: "Rejetée",
            APPROVED: "Approuvée"

          },
          TITLE: {
            AFFILIATED: "Organisations affiliées",
            PENDING_AFFILIATIONS: "Demandes d'affiliation en attente",
            MANAGING_ORGANIZATION: "Organisations parentes"
          }
        },
        ADD: {
          TITLE: "Ajouter une affiliation",
          ADD_AFFILIATION: "Ajouter"
        }
      },
      DETAILS: {
        COMPANY_NAME: "Raison sociale (Enseigne de l'établissement)",
        COMPANY_LEGAL_FORM: "Statut juridique",
        ADDRESS: "Adresse",
        CITY: "Ville",
        ZIP_CODE: "Code postal",
        COUNTRY: "Pays",
        REGISTRATION_NUMBER: "Numéro d'identification",
        TITLE: "Organisation",
        ASSIGN_ROVER: {
          TITLE: "Assigner un rover",
          FORM: {
            SERIAL_NUMBER: "Rover",
            SUBMIT: "Assigner"
          }
        },
        MANAGE_FUNCTIONAL_EMAIL_BUTTON: "Gérer les mails de l'organisation",
        CREATE_AFFILIATION_BUTTON: "Ajouter un affilié",
        ASSIGN_ROVER_BUTTON: "Assigner un rover",
        ORGANIZATION_TYPE: "Type d'organisation",
      },
      COMPANY_LEGAL_FORM: {
        EI: "Entreprise Individuelle",
        GAEC: "Groupement Agricole d'Exploitation en Commun",
        EARL: "Exploitation Agricole à Responsabilité Limitée",
        SAS: "Société par Actions Simplifiée",
        SCEA: "Société Civile d’Exploitation Agricole",
        SCA: "Société en Commandite par Actions"
      },
      CONFIRM_DELETE: "Êtes-vous sûr de vouloir supprimer cette organisation ?",
      LIST: {
        CREATE_ORGANIZATION_BUTTON: "Créer une organisation",
        MANAGE_ORGANIZATION_TYPE_BUTTON: "Gérer les types d'organisation",
        HEADER: {
          COMPANY_NAME: "Raison sociale",
          ADDRESS: "Adresse",
          CITY: "Ville",
          COUNTRY: "Pays",
          ZIP_CODE: "Code postal",
          COMPANY_LEGAL_FORM: "Statut juridique",
          ACTIONS: "Actions",
          ORGANIZATION_TYPE: "Type d'organisation"
        },
      },
      ADD_ORGANIZATION: {
        TITLE: "Créer une organisation",
        FORM: {
          COMPANY_NAME: "Raison sociale (Enseigne de l'établissement)",
          COMPANY_LEGAL_FORM: "Statut juridique",
          ADDRESS: "Adresse",
          CITY: "Ville",
          ZIP_CODE: "Code postal",
          COUNTRY: "Pays",
          REGISTRATION_NUMBER: "Numéro d'identification",
          ORGANIZATION_TYPE: "Type d'organisation",
          ACTIONS: "Actions",
          SUBMIT: "Créer",
          ERRORS: {
            ADDRESS2_REQUIRES_ADDRESS1: "Adresse 1 est requise pour l'adresse 2",
          }
        }
      },
      ORGANIZATION_TYPE: {
        LIST: {
          CREATE_ORGANIZATIONS_TYPE_BUTTON: "Créer un type d'organisation",
          HEADER: {
            ORGANIZATION_TYPE_NAME: "Nom du type d'organisation",
            DESCRIPTION: "Description",
            CAN_MANAGE: "Peut gérer d'autres organisations",
            ACTIONS: "Actions"
          },
          ACTIONS: {
            CONFIRM_SUPPRESSION: "Êtes-vous sûr de vouloir supprimer ce type d'organisation ?",
          }
        },
        EDIT: {
          TITLE: "Editer le type d'organisation",
        },
        ADD: {
          TITLE: "Ajouter un type d'organisation",
        },
        FORM: {
          ORGANIZATION_TYPE_NAME: "Nom du type d'organisation",
          DESCRIPTION: "Description",
          CAN_MANAGE: "Peut gérer d'autres organisations",
          EDIT_SUBMIT: "Editer",
          ADD_SUBMIT: "Ajouter"
        }
      },
      FUNCTIONAL_EMAIL: {
        TITLE: "Mails de l'organisation",
        LIST: {
          EMAIL: "Adresse mail",
          ROLE: "Rôle",
          ACTIONS: "Actions"
        },
        ROLE: {
          PRINCIPAL: "Principale",
          MANAGEMENT: "Gestion",
          SALES: "Vente",
          ACCOUNTING: "Comptabilité",
          MAINTENANCE: "Maintenance",
          SUPPORT: "Support"
        },
        FORM: {
          EMAIL: "Email",
          ROLE: "Rôle",
          BUTTON: {
            ADD: "Ajouter",
          }
        }
      }
    },
    ALERTS: {
      RECONNECTION: {
        TEXT: "Reconnection en cours..."
      }
    },
    MISSION: {
      TYPE: {
        AUTONOMOUS_WEEDING: "Désherbage autonome",
        GPS_WEEDING: "Désherbage guidé par GPS",
        DATA_ACQUISITION: "Acquisition de données guidé par GPS",
        MANUAL_DATA_ACQUISITION: "Acquisition de données manuelle",
        BLIND_WEEDING: "Désherbage à l'aveugle",
        CARTOGRAPHY_WEEDING: "Désherbage par cartographie"
      },
      STATUS: {
        STARTING: "Démarrage",
        EXECUTING: "Execution en cours",
        COMPLETING: "Finalisation",
        COMPLETED: "Terminée",
        ABORTING: "Annulation en cours",
        ABORTED: "Annulé",
        FORCED_ABORTED: "Annulation d'urgence",
        ERROR: "Erreur",
      },
      ORCHESTRATION_STATUS: {
        STARTING: "Démarrage",
        STARTED: "Context initialisé",
        INIT_NAVIGATION_TO_TARGET: "Initialisation de la navigation vers la cible",
        INIT_NAVIGATION_TO_CHARGING_STATION: "Initialisation de la navigation vers la station de recharge",
        WAITING_FOR_ROVER_TO_REACH_STARTING_POINT_TO_TARGET: "En attente du rover pour atteindre le point de départ vers la cible",
        WAITING_FOR_ROVER_TO_REACH_STARTING_POINT_TO_CHARGING_STATION: "En attente du rover pour atteindre le point de départ vers la station de recharge",
        WAITING_FOR_AVAILABLE_PATH_TO_TARGET: "En attente d'un chemin disponible vers la cible",
        WAITING_FOR_AVAILABLE_CHARGING_STATION: "En attente d'une station de recharge disponible",
        WAITING_FOR_AVAILABLE_PATH_TO_CHARGING_STATION: "En attente d'un chemin disponible vers la station de recharge",
        TRAVELING_TO_TARGET: "En déplacement vers la cible",
        TRAVELING_TO_TARGET_WAITING_FOR_OPERATOR: "En déplacement vers la cible, en attente de l'opérateur",
        REACHED_TARGET: "Cible atteinte",
        WORKING: "En cours de travail",
        WORK_COMPLETED: "Travail terminé",
        ABORTING_WORK: "Annulation du travail en cours",
        WORK_ABORTED: "Travail annulé",
        TRAVELING_TO_CHARGING_STATION: "En déplacement vers la station de recharge",
        ASSIGNING_CHARGING_STATION: "Assignation de la station de recharge",
        ASSIGNED_CHARGING_STATION: "Station de recharge assignée",
        REACHED_CHARGING_STATION: "Station de recharge atteinte",
        WAITING_FOR_OPERATOR_INTERVENTION: "En attente d'une intervention de l'opérateur",
        LIVENESS_PROBE: "Vérification de la connexion",
        READINESS_PROBE: "Vérification de la disponibilité",
        EXECUTING: "Execution en cours",
        COMPLETING: "Finalisation",
        COMPLETED: "Terminée",
        ABORTING: "Annulation en cours",
        ABORTED: "Annulé",
        FORCED_ABORTED: "Annulation forcée",
        FORCE_ABORTED: "Annulation forcée",
        ERROR: "Erreur",
      }
    },
    KPI: {
      TITLE: {
        ROVER_WORKING_HOUR: "Heures de travail par type de cultures",
        ROVER_WEEDED_SURFACE: "Surface de désherbage par type de cultures",
      },
      GRANULARITY: {
        DAY: "Jour",
        WEEK: "Semaine",
        MONTH: "Mois",
        YEAR: "Année"
      },
      WORKING_HOUR_BY_CROP: {
        CHART: {
          X_AXIS_LABEL: "Date",
          Y_AXIS_LABEL: "Heures travaillées",
          LEGEND_TITLE: "Type de cultures",
          NO_DATA: "Pas de données disponibles pour cette sélection",
        },
        FORM: {
          ROVERS: "Rovers selectionnés",
          CROP_TYPES: "Types de cultures selectionnés",
          GRANULARITY: "Echelle de temps",
          START_DATE: "Date de début",
          END_DATE: "Date de fin"
        }
      },
      WEEDED_SURFACE_BY_CROP: {
        CHART: {
          X_AXIS_LABEL: "Date",
          Y_AXIS_LABEL: "Hectares désherbés",
          LEGEND_TITLE: "Type de cultures",
          NO_DATA: "Pas de données disponibles pour cette sélection",
        },
        FORM: {
          ROVERS: "Rovers selectionnés",
          CROP_TYPES: "Types de cultures selectionnés",
          GRANULARITY: "Echelle de temps",
          START_DATE: "Date de début",
          END_DATE: "Date de fin"
        }
      }
    },
    MAINTENANCE: {
      LIST: {
        TITLE: "Rovers nécessitant une maintenance",
        MAINTENANCE_TYPE_BUTTON: "Types de maintenance",
        HEADER: {
          ROVER_ID: "id du rover",
          ROVER_SERIAL_NUMBER: "Numéro de série du rover",
          OWNER_COMPANY: "Organisation propriétaire",
        }
      },
      SCHEDULE: {
        LIST: {
          TITLE: "Programmes de maintenance",
          HEADER: {
            MAINTENANCE_TYPE: "Type de maintenance",
            LAST_MAINTENANCE_DATE: "Dernière date de maintenance",
            DUE_DATE: "Date d'échéance",
            LAST_MAINTENANCE_WORKING_HOURS: "Heures de fonctionnement au dernier entretien",
            DUE_WORKING_HOURS: "Heures de fonctionnement à échéance",
          }
        },
      },
      MAINTENANCE_TYPE: {
        LIST: {
          CREATE_MAINTENANCE_TYPE_BUTTON: "Créer un type de maintenance",
          FORM: {
            ROVER_MODEL: "Par modèle"
          },
          HEADER: {
            MAINTENANCE_TYPE_LABEL: "Type de maintenance",
            ROVER: "Modèles de rover associés",
            DEPENDING_MAINTENANCE_TYPE: "Dépendance",
            OPERATING_HOUR: "Périodicité horaire de fonctionnement",
            CALENDAR_PERIODICITY: "Périodicité calendaire",
            UNIT: {
              DAYS: "jour(s)",
              WEEKS: "semaine(s)",
              MONTHS: "mois"
            },
            NOTIFICATION_ENABLED: "Notification",
            ACTIONS: "Actions"
          }
        },
        EDIT: {
          TITLE: "Editer le type de maintenance",
        },
        ADD: {
          TITLE: "Ajouter un type de maintenance",
        },
        FORM: {
          MAINTENANCE_TYPE_LABEL: "Type de maintenance",
          ROVER: "Modèles de rover associés",
          OPERATING_HOUR: "Périodicité horaire de fonctionnement",
          CALENDAR_PERIODICITY: "Périodicité calendaire",
          DEPENDING_MAINTENANCE_TYPE: "Dépendance",
          UNIT: {
            DAYS: "Jours",
            WEEKS: "Semaines",
            MONTHS: "Mois"
          },
          NOTIFICATION_ENABLED: "Notification",
          EDIT_SUBMIT: "Editer",
          ADD_SUBMIT: "Ajouter"
        }
      },
    },
    CONFIRMATION_DIALOG: {
      TITLE: "Confirmation",
      CANCEL: "Annuler",
      CONFIRM: "Confirmer",
    },
    ERROR: {
      SERVER: {
        CODE: {
          1: "Utilisateur introuvable"
        },
        STATUS: {
          0: "Oops une erreur est survenue ! Veuillez réessayer.",
          400: "Oops une erreur est survenue ! Veuillez réessayer.",
          401: "Identifiants incorrects",
          403: "Accès refusé !",
          404: "Contenu introuvable !",
          405: "Fonctionnalité indisponible !",
          500: "Oops une erreur est survenue ! Veuillez réessayer.",
        }
      }
    }
  }
}
