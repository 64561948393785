import { getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';
import {environment} from '@env/environment';

export function faroInitializer(): Function {
  return async () => {
    initializeFaro({
      url: environment.faro.url,
      app: {
        name: environment.faro.app.name,
        version: environment.faro.app.version,
        environment: environment.faro.app.environment
      },
      instrumentations: [
        // Mandatory, omits default instrumentations otherwise.
        ...getWebInstrumentations(),

        // Tracing package to get end-to-end visibility for HTTP requests.
        new TracingInstrumentation(),
      ],
    });
  };
}
