import { faro, stringifyExternalJson } from '@grafana/faro-core';
import { throttle } from '../../../utils';
import { getItem, removeItem, setItem, webStorageType } from '../../../utils/webStorage';
import { STORAGE_KEY, STORAGE_UPDATE_DELAY } from './sessionConstants';
import { getSessionMetaUpdateHandler, getUserSessionUpdater } from './sessionManagerUtils';
export class VolatileSessionsManager {
  constructor() {
    this.updateSession = throttle(() => this.updateUserSession(), STORAGE_UPDATE_DELAY);
    this.updateUserSession = getUserSessionUpdater({
      fetchUserSession: VolatileSessionsManager.fetchUserSession,
      storeUserSession: VolatileSessionsManager.storeUserSession
    });
    this.init();
  }
  static removeUserSession() {
    removeItem(STORAGE_KEY, VolatileSessionsManager.storageTypeSession);
  }
  static storeUserSession(session) {
    setItem(STORAGE_KEY, stringifyExternalJson(session), VolatileSessionsManager.storageTypeSession);
  }
  static fetchUserSession() {
    const storedSession = getItem(STORAGE_KEY, VolatileSessionsManager.storageTypeSession);
    if (storedSession) {
      return JSON.parse(storedSession);
    }
    return null;
  }
  init() {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.updateSession();
      }
    });
    // Users can call the setSession() method, so we need to sync this with the local storage session
    faro.metas.addListener(getSessionMetaUpdateHandler({
      fetchUserSession: VolatileSessionsManager.fetchUserSession,
      storeUserSession: VolatileSessionsManager.storeUserSession
    }));
  }
}
VolatileSessionsManager.storageTypeSession = webStorageType.session;
