import {ErrorHandler, Injectable} from "@angular/core";
import {HttpErrorResponse} from "@angular/common/http";
import {map} from "rxjs/operators";
import {ErrorService} from "@app/service/error.service";
import {LoadingService} from "@shared/services/loading.service";
import {ToastService} from "@shared/services/toast.service";
import {faro} from "@grafana/faro-web-sdk";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private errorService: ErrorService,
    private toastService: ToastService,
    private loadingService: LoadingService
  ) {
  }

  handleError(error: any) {

    let message;
    let stackTrace;

    if (error instanceof HttpErrorResponse) {
      // Server Errors
      this.loadingService.clearLoadings();
      this.errorService.getServerMessage(error).pipe(
        map(serverMessage => {
          this.toastService.error(serverMessage)
        })
      ).subscribe();
      stackTrace = this.errorService.getServerStack(error);

    } else {
      // Client Error
      message = this.errorService.getClientMessage(error);
      stackTrace = this.errorService.getClientStack(error);
      this.toastService.error(message);
    }

    faro.api.pushError(error);
    console.error('Error caught by the global error handler', error);
  }
}
