/*
 * Copyright The OpenTelemetry Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
var __values = this && this.__values || function (o) {
  var s = typeof Symbol === "function" && Symbol.iterator,
    m = s && o[s],
    i = 0;
  if (m) return m.call(o);
  if (o && typeof o.length === "number") return {
    next: function () {
      if (o && i >= o.length) o = void 0;
      return {
        value: o && o[i++],
        done: !o
      };
    }
  };
  throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
// Much of the logic here overlaps with the same utils file in opentelemetry-instrumentation-fetch
// These may be unified in the future.
import * as api from '@opentelemetry/api';
var DIAG_LOGGER = api.diag.createComponentLogger({
  namespace: '@opentelemetry/opentelemetry-instrumentation-xml-http-request/utils'
});
/**
 * Helper function to determine payload content length for XHR requests
 * @param body
 * @returns content length
 */
export function getXHRBodyLength(body) {
  if (typeof Document !== 'undefined' && body instanceof Document) {
    return new XMLSerializer().serializeToString(document).length;
  }
  // XMLHttpRequestBodyInit expands to the following:
  if (body instanceof Blob) {
    return body.size;
  }
  // ArrayBuffer | ArrayBufferView
  if (body.byteLength !== undefined) {
    return body.byteLength;
  }
  if (body instanceof FormData) {
    return getFormDataSize(body);
  }
  if (body instanceof URLSearchParams) {
    return getByteLength(body.toString());
  }
  if (typeof body === 'string') {
    return getByteLength(body);
  }
  DIAG_LOGGER.warn('unknown body type');
  return undefined;
}
var TEXT_ENCODER = new TextEncoder();
function getByteLength(s) {
  return TEXT_ENCODER.encode(s).byteLength;
}
function getFormDataSize(formData) {
  var e_1, _a;
  var size = 0;
  try {
    for (var _b = __values(formData.entries()), _c = _b.next(); !_c.done; _c = _b.next()) {
      var _d = __read(_c.value, 2),
        key = _d[0],
        value = _d[1];
      size += key.length;
      if (value instanceof Blob) {
        size += value.size;
      } else {
        size += value.length;
      }
    }
  } catch (e_1_1) {
    e_1 = {
      error: e_1_1
    };
  } finally {
    try {
      if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
    } finally {
      if (e_1) throw e_1.error;
    }
  }
  return size;
}
