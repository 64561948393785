import { isFunction } from '@grafana/faro-core';
let currentHref;
let pageId;
export function createPageMeta({
  generatePageId,
  initialPageMeta
} = {}) {
  const pageMeta = () => {
    const locationHref = location.href;
    if (isFunction(generatePageId) && currentHref !== locationHref) {
      currentHref = locationHref;
      pageId = generatePageId(location);
    }
    return {
      page: Object.assign(Object.assign({
        url: locationHref
      }, pageId ? {
        id: pageId
      } : {}), initialPageMeta)
    };
  };
  return pageMeta;
}
