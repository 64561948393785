import { getDetailsFromErrorArgs } from './getErrorDetails';
export function registerOnerror(api) {
  const oldOnerror = window.onerror;
  window.onerror = (...args) => {
    try {
      const {
        value,
        type,
        stackFrames
      } = getDetailsFromErrorArgs(args);
      if (value) {
        api.pushError(new Error(value), {
          type,
          stackFrames
        });
      }
    } finally {
      oldOnerror === null || oldOnerror === void 0 ? void 0 : oldOnerror.apply(window, args);
    }
  };
}
