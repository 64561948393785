import { isDomError, isDomException, isError, isErrorEvent, isEvent, isObject, isString } from '@grafana/faro-core';
import { domErrorType, domExceptionType, objectEventValue, unknownSymbolString } from './const';
import { getValueAndTypeFromMessage } from './getValueAndTypeFromMessage';
import { buildStackFrame, getStackFramesFromError } from './stackFrames';
export function getErrorDetails(evt) {
  let value;
  let type;
  let stackFrames = [];
  let isDomErrorRes;
  let isEventRes;
  if (isErrorEvent(evt) && evt.error) {
    value = evt.error.message;
    type = evt.error.name;
    stackFrames = getStackFramesFromError(evt.error);
  } else if ((isDomErrorRes = isDomError(evt)) || isDomException(evt)) {
    const {
      name,
      message
    } = evt;
    type = name !== null && name !== void 0 ? name : isDomErrorRes ? domErrorType : domExceptionType;
    value = message ? `${type}: ${message}` : type;
  } else if (isError(evt)) {
    value = evt.message;
    stackFrames = getStackFramesFromError(evt);
  } else if (isObject(evt) || (isEventRes = isEvent(evt))) {
    type = isEventRes ? evt.constructor.name : undefined;
    value = `${objectEventValue} ${Object.keys(evt)}`;
  }
  return [value, type, stackFrames];
}
export function getDetailsFromErrorArgs(args) {
  const [evt, source, lineno, colno, error] = args;
  let value;
  let type;
  let stackFrames = [];
  const eventIsString = isString(evt);
  const initialStackFrame = buildStackFrame(source, unknownSymbolString, lineno, colno);
  if (error || !eventIsString) {
    [value, type, stackFrames] = getErrorDetails(error !== null && error !== void 0 ? error : evt);
    if (stackFrames.length === 0) {
      stackFrames = [initialStackFrame];
    }
  } else if (eventIsString) {
    [value, type] = getValueAndTypeFromMessage(evt);
    stackFrames = [initialStackFrame];
  }
  return {
    value,
    type,
    stackFrames
  };
}
export function getDetailsFromConsoleErrorArgs(args, serializer) {
  if (isError(args[0])) {
    return getDetailsFromErrorArgs(args);
  } else {
    return {
      value: serializer(args)
    };
  }
}
