import { isObject } from './is';
export function getCircularDependencyReplacer() {
  const valueSeen = new WeakSet();
  return function (_key, value) {
    if (isObject(value) && value !== null) {
      if (valueSeen.has(value)) {
        return null;
      }
      valueSeen.add(value);
    }
    return value;
  };
}
export function stringifyExternalJson(json = {}) {
  return JSON.stringify(json !== null && json !== void 0 ? json : {}, getCircularDependencyReplacer());
}
export function stringifyObjectValues(obj = {}) {
  const o = {};
  for (const [key, value] of Object.entries(obj)) {
    o[key] = isObject(value) && value !== null ? stringifyExternalJson(value) : String(value);
  }
  return o;
}
