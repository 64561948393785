import { allLogLevels, BaseInstrumentation, defaultErrorArgsSerializer, defaultLogArgsSerializer, LogLevel, VERSION } from '@grafana/faro-core';
import { getDetailsFromConsoleErrorArgs } from '../errors/getErrorDetails';
export class ConsoleInstrumentation extends BaseInstrumentation {
  constructor(options = {}) {
    super();
    this.options = options;
    this.name = '@grafana/faro-web-sdk:instrumentation-console';
    this.version = VERSION;
    this.errorSerializer = defaultLogArgsSerializer;
  }
  initialize() {
    var _a, _b, _c, _d;
    this.options = Object.assign(Object.assign({}, this.options), this.config.consoleInstrumentation);
    const serializeErrors = ((_a = this.options) === null || _a === void 0 ? void 0 : _a.serializeErrors) || !!((_b = this.options) === null || _b === void 0 ? void 0 : _b.errorSerializer);
    this.errorSerializer = serializeErrors ? (_d = (_c = this.options) === null || _c === void 0 ? void 0 : _c.errorSerializer) !== null && _d !== void 0 ? _d : defaultErrorArgsSerializer : defaultLogArgsSerializer;
    allLogLevels.filter(level => {
      var _a, _b;
      return !((_b = (_a = this.options) === null || _a === void 0 ? void 0 : _a.disabledLevels) !== null && _b !== void 0 ? _b : ConsoleInstrumentation.defaultDisabledLevels).includes(level);
    }).forEach(level => {
      /* eslint-disable-next-line no-console */
      console[level] = (...args) => {
        var _a, _b;
        try {
          if (level === LogLevel.ERROR && !((_a = this.options) === null || _a === void 0 ? void 0 : _a.consoleErrorAsLog)) {
            const {
              value,
              type,
              stackFrames
            } = getDetailsFromConsoleErrorArgs(args, this.errorSerializer);
            if (value && !type && !stackFrames) {
              this.api.pushError(new Error(ConsoleInstrumentation.consoleErrorPrefix + value));
              return;
            }
            this.api.pushError(new Error(ConsoleInstrumentation.consoleErrorPrefix + value), {
              type,
              stackFrames
            });
          } else if (level === LogLevel.ERROR && ((_b = this.options) === null || _b === void 0 ? void 0 : _b.consoleErrorAsLog)) {
            const {
              value,
              type,
              stackFrames
            } = getDetailsFromConsoleErrorArgs(args, this.errorSerializer);
            this.api.pushLog(value ? [ConsoleInstrumentation.consoleErrorPrefix + value] : args, {
              level,
              context: {
                value: value !== null && value !== void 0 ? value : '',
                type: type !== null && type !== void 0 ? type : '',
                stackFrames: (stackFrames === null || stackFrames === void 0 ? void 0 : stackFrames.length) ? defaultErrorArgsSerializer(stackFrames) : ''
              }
            });
          } else {
            this.api.pushLog(args, {
              level
            });
          }
        } catch (err) {
          this.logError(err);
        } finally {
          this.unpatchedConsole[level](...args);
        }
      };
    });
  }
}
ConsoleInstrumentation.defaultDisabledLevels = [LogLevel.DEBUG, LogLevel.TRACE, LogLevel.LOG];
ConsoleInstrumentation.consoleErrorPrefix = 'console.error: ';
