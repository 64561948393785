import { createInternalLogger, defaultBatchingConfig, defaultGlobalObjectKey, defaultInternalLoggerLevel, defaultLogArgsSerializer, defaultUnpatchedConsole, isObject } from '@grafana/faro-core';
import { defaultEventDomain } from '../consts';
import { parseStacktrace } from '../instrumentations';
import { defaultSessionTrackingConfig } from '../instrumentations/session';
import { browserMeta } from '../metas';
import { k6Meta } from '../metas/k6';
import { createPageMeta } from '../metas/page';
import { FetchTransport } from '../transports';
import { getWebInstrumentations } from './getWebInstrumentations';
export function makeCoreConfig(browserConfig) {
  var _a;
  const transports = [];
  const internalLogger = createInternalLogger(browserConfig.unpatchedConsole, browserConfig.internalLoggerLevel);
  if (browserConfig.transports) {
    if (browserConfig.url || browserConfig.apiKey) {
      internalLogger.error('if "transports" is defined, "url" and "apiKey" should not be defined');
    }
    transports.push(...browserConfig.transports);
  } else if (browserConfig.url) {
    transports.push(new FetchTransport({
      url: browserConfig.url,
      apiKey: browserConfig.apiKey
    }));
  } else {
    internalLogger.error('either "url" or "transports" must be defined');
  }
  const {
    app,
    batching,
    beforeSend,
    consoleInstrumentation,
    ignoreErrors,
    sessionTracking,
    trackResources,
    trackWebVitalsAttribution,
    user,
    view,
    // properties with default values
    dedupe = true,
    eventDomain = defaultEventDomain,
    globalObjectKey = defaultGlobalObjectKey,
    instrumentations = getWebInstrumentations(),
    internalLoggerLevel = defaultInternalLoggerLevel,
    isolate = false,
    logArgsSerializer = defaultLogArgsSerializer,
    metas = createDefaultMetas(browserConfig),
    paused = false,
    preventGlobalExposure = false,
    unpatchedConsole = defaultUnpatchedConsole
  } = browserConfig;
  return {
    app,
    batching: Object.assign(Object.assign({}, defaultBatchingConfig), batching),
    dedupe: dedupe,
    globalObjectKey,
    instrumentations,
    internalLoggerLevel,
    isolate,
    logArgsSerializer,
    metas,
    parseStacktrace,
    paused,
    preventGlobalExposure,
    transports,
    unpatchedConsole,
    beforeSend,
    eventDomain,
    ignoreErrors,
    // ignore cloud collector urls by default. These are URLs ending with /collect or /collect/ followed by alphanumeric characters.
    ignoreUrls: ((_a = browserConfig.ignoreUrls) !== null && _a !== void 0 ? _a : []).concat([/\/collect(?:\/[\w]*)?$/]),
    sessionTracking: Object.assign(Object.assign({}, defaultSessionTrackingConfig), sessionTracking),
    user,
    view,
    trackResources,
    trackWebVitalsAttribution,
    consoleInstrumentation
  };
}
function createDefaultMetas(browserConfig) {
  var _a, _b;
  const {
    page,
    generatePageId
  } = (_a = browserConfig === null || browserConfig === void 0 ? void 0 : browserConfig.pageTracking) !== null && _a !== void 0 ? _a : {};
  const initialMetas = [browserMeta, createPageMeta({
    generatePageId,
    initialPageMeta: page
  }), ...((_b = browserConfig.metas) !== null && _b !== void 0 ? _b : [])];
  const isK6BrowserSession = isObject(window.k6);
  if (isK6BrowserSession) {
    return [...initialMetas, k6Meta];
  }
  return initialMetas;
}
