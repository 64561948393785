import { isEmpty, isString } from '../../utils/is';
export function initializeMetaAPI(_unpatchedConsole, internalLogger, _config, metas, _transports) {
  internalLogger.debug('Initializing meta API');
  let metaSession = undefined;
  let metaUser = undefined;
  let metaView = undefined;
  let metaPage = undefined;
  const setUser = user => {
    if (metaUser) {
      metas.remove(metaUser);
    }
    metaUser = {
      user
    };
    metas.add(metaUser);
  };
  const setSession = (session, options) => {
    const overrides = options === null || options === void 0 ? void 0 : options.overrides;
    if (metaSession) {
      metas.remove(metaSession);
    }
    metaSession = {
      session: Object.assign(Object.assign({}, isEmpty(session) ? undefined : session), overrides ? {
        overrides
      } : {})
    };
    metas.add(metaSession);
  };
  const getSession = () => metas.value.session;
  const setView = (view, options) => {
    var _a;
    if (options === null || options === void 0 ? void 0 : options.overrides) {
      setSession(getSession(), {
        overrides: options.overrides
      });
    }
    if (((_a = metaView === null || metaView === void 0 ? void 0 : metaView.view) === null || _a === void 0 ? void 0 : _a.name) === (view === null || view === void 0 ? void 0 : view.name)) {
      return;
    }
    const previousView = metaView;
    metaView = {
      view
    };
    metas.add(metaView);
    if (previousView) {
      metas.remove(previousView);
    }
  };
  const getView = () => metas.value.view;
  const setPage = page => {
    var _a;
    const pageMeta = isString(page) ? Object.assign(Object.assign({}, (_a = metaPage === null || metaPage === void 0 ? void 0 : metaPage.page) !== null && _a !== void 0 ? _a : getPage()), {
      id: page
    }) : page;
    if (metaPage) {
      metas.remove(metaPage);
    }
    metaPage = {
      page: pageMeta
    };
    metas.add(metaPage);
  };
  const getPage = () => metas.value.page;
  return {
    setUser,
    resetUser: setUser,
    setSession,
    resetSession: setSession,
    getSession,
    setView,
    getView,
    setPage,
    getPage
  };
}
