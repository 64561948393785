import { BaseInstrumentation, dateNow, EVENT_SESSION_EXTEND, EVENT_SESSION_RESUME, EVENT_SESSION_START, VERSION } from '@grafana/faro-core';
import { createSession } from '../../metas';
import { getSessionManagerByConfig, isSampled } from './sessionManager';
import { PersistentSessionsManager } from './sessionManager/PersistentSessionsManager';
import { createUserSessionObject, isUserSessionValid } from './sessionManager/sessionManagerUtils';
export class SessionInstrumentation extends BaseInstrumentation {
  constructor() {
    super(...arguments);
    this.name = '@grafana/faro-web-sdk:instrumentation-session';
    this.version = VERSION;
  }
  sendSessionStartEvent(meta) {
    var _a, _b;
    const session = meta.session;
    if (session && session.id !== ((_a = this.notifiedSession) === null || _a === void 0 ? void 0 : _a.id)) {
      if (this.notifiedSession && this.notifiedSession.id === ((_b = session.attributes) === null || _b === void 0 ? void 0 : _b['previousSession'])) {
        this.api.pushEvent(EVENT_SESSION_EXTEND, {}, undefined, {
          skipDedupe: true
        });
        this.notifiedSession = session;
        return;
      }
      this.notifiedSession = session;
      // no need to add attributes and session id, they are included as part of meta
      // automatically
      this.api.pushEvent(EVENT_SESSION_START, {}, undefined, {
        skipDedupe: true
      });
    }
  }
  createInitialSession(SessionManager, sessionsConfig) {
    var _a, _b, _c, _d;
    let userSession = SessionManager.fetchUserSession();
    if (sessionsConfig.persistent && sessionsConfig.maxSessionPersistenceTime && userSession) {
      const now = dateNow();
      const shouldClearPersistentSession = userSession.lastActivity < now - sessionsConfig.maxSessionPersistenceTime;
      if (shouldClearPersistentSession) {
        PersistentSessionsManager.removeUserSession();
        userSession = null;
      }
    }
    let lifecycleType;
    let initialSession;
    if (isUserSessionValid(userSession)) {
      const sessionId = userSession === null || userSession === void 0 ? void 0 : userSession.sessionId;
      initialSession = createUserSessionObject({
        sessionId,
        isSampled: userSession.isSampled || false,
        started: userSession === null || userSession === void 0 ? void 0 : userSession.started
      });
      const userSessionMeta = userSession === null || userSession === void 0 ? void 0 : userSession.sessionMeta;
      initialSession.sessionMeta = {
        id: sessionId,
        attributes: Object.assign(Object.assign(Object.assign({}, (_a = sessionsConfig.session) === null || _a === void 0 ? void 0 : _a.attributes), userSessionMeta === null || userSessionMeta === void 0 ? void 0 : userSessionMeta.attributes), {
          // For valid resumed sessions we do not want to recalculate the sampling decision on each init phase.
          isSampled: initialSession.isSampled.toString()
        }),
        overrides: userSessionMeta === null || userSessionMeta === void 0 ? void 0 : userSessionMeta.overrides
      };
      lifecycleType = EVENT_SESSION_RESUME;
    } else {
      const sessionId = (_c = (_b = sessionsConfig.session) === null || _b === void 0 ? void 0 : _b.id) !== null && _c !== void 0 ? _c : createSession().id;
      initialSession = createUserSessionObject({
        sessionId,
        isSampled: isSampled()
      });
      initialSession.sessionMeta = {
        id: sessionId,
        attributes: Object.assign({
          isSampled: initialSession.isSampled.toString()
        }, (_d = sessionsConfig.session) === null || _d === void 0 ? void 0 : _d.attributes)
      };
      lifecycleType = EVENT_SESSION_START;
    }
    return {
      initialSession,
      lifecycleType
    };
  }
  registerBeforeSendHook(SessionManager) {
    var _a;
    const {
      updateSession
    } = new SessionManager();
    (_a = this.transports) === null || _a === void 0 ? void 0 : _a.addBeforeSendHooks(item => {
      var _a, _b, _c;
      updateSession();
      const attributes = (_a = item.meta.session) === null || _a === void 0 ? void 0 : _a.attributes;
      if (attributes && (attributes === null || attributes === void 0 ? void 0 : attributes['isSampled']) === 'true') {
        let newItem = JSON.parse(JSON.stringify(item));
        const newAttributes = (_b = newItem.meta.session) === null || _b === void 0 ? void 0 : _b.attributes;
        newAttributes === null || newAttributes === void 0 ? true : delete newAttributes['isSampled'];
        if (Object.keys(newAttributes !== null && newAttributes !== void 0 ? newAttributes : {}).length === 0) {
          (_c = newItem.meta.session) === null || _c === void 0 ? true : delete _c.attributes;
        }
        return newItem;
      }
      return null;
    });
  }
  initialize() {
    this.logDebug('init session instrumentation');
    const sessionTrackingConfig = this.config.sessionTracking;
    if (sessionTrackingConfig === null || sessionTrackingConfig === void 0 ? void 0 : sessionTrackingConfig.enabled) {
      const SessionManager = getSessionManagerByConfig(sessionTrackingConfig);
      this.registerBeforeSendHook(SessionManager);
      const {
        initialSession,
        lifecycleType
      } = this.createInitialSession(SessionManager, sessionTrackingConfig);
      SessionManager.storeUserSession(initialSession);
      const initialSessionMeta = initialSession.sessionMeta;
      this.notifiedSession = initialSessionMeta;
      this.api.setSession(initialSessionMeta);
      if (lifecycleType === EVENT_SESSION_START) {
        this.api.pushEvent(EVENT_SESSION_START, {}, undefined, {
          skipDedupe: true
        });
      }
      if (lifecycleType === EVENT_SESSION_RESUME) {
        this.api.pushEvent(EVENT_SESSION_RESUME, {}, undefined, {
          skipDedupe: true
        });
      }
    }
    this.metas.addListener(this.sendSessionStartEvent.bind(this));
  }
}
