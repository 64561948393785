export function isTypeof(value, type) {
  return typeof value === type;
}
export function isToString(value, type) {
  return Object.prototype.toString.call(value) === `[object ${type}]`;
}
export function isInstanceOf(value, reference) {
  try {
    return value instanceof reference;
  } catch (err) {
    return false;
  }
}
export const isUndefined = value => isTypeof(value, 'undefined');
export const isNull = value => isTypeof(value, 'null');
export const isString = value => isTypeof(value, 'string');
export const isNumber = value => isTypeof(value, 'number') && !isNaN(value) || isTypeof(value, 'bigint');
export const isInt = value => isNumber(value) && Number.isInteger(value);
export const isBoolean = value => isTypeof(value, 'boolean');
export const isSymbol = value => isTypeof(value, 'symbol');
export const isObject = value => !isNull(value) && isTypeof(value, 'object');
export const isFunction = value => isTypeof(value, 'function');
export const isArray = value => isToString(value, 'Array');
export const isRegExp = value => isToString(value, 'RegExp');
export const isThenable = value => isFunction(value === null || value === void 0 ? void 0 : value.then);
export const isPrimitive = value => !isObject(value) && !isFunction(value);
export const isEventDefined = typeof Event !== 'undefined';
export const isEvent = value => isEventDefined && isInstanceOf(value, Event);
export const isErrorDefined = typeof Error !== 'undefined';
export const isError = value => isErrorDefined && isInstanceOf(value, Error);
export const isErrorEvent = value => isToString(value, 'ErrorEvent');
export const isDomError = value => isToString(value, 'DOMError');
export const isDomException = value => isToString(value, 'DOMException');
export const isElementDefined = typeof Element !== 'undefined';
export const isElement = value => isElementDefined && isInstanceOf(value, Element);
export const isMapDefined = typeof Map !== 'undefined';
export const isMap = value => isMapDefined && isInstanceOf(value, Map);
export const isSyntheticEvent = value => isObject(value) && 'nativeEvent' in value && 'preventDefault' in value && 'stopPropagation' in value;
export function isEmpty(value) {
  if (value == null) {
    return true;
  }
  if (isArray(value) || isString(value)) {
    return value.length === 0;
  }
  if (isObject(value)) {
    return Object.keys(value).length === 0;
  }
  return false;
}
